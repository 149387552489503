"use client";

import type { Menu } from "@types";

import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";

const Header = ({
  isMobile,
  selectedMenu,
  forceDarkTheme,
}: {
  isMobile: boolean;
  selectedMenu: Menu;
  forceDarkTheme?: boolean;
}): JSX.Element => {
  return (
    <div className="sticky top-0 z-50 w-full bg-background">
      <header className="mx-auto w-full max-w-screen-md">
        <HeaderDesktop
          className="hidden md:flex"
          selectedMenu={selectedMenu}
          forceDarkTheme={forceDarkTheme}
          isMobile={isMobile}
        />
        <HeaderMobile
          className="md:hidden"
          isMobile={isMobile}
          selectedMenu={selectedMenu}
          forceDarkTheme={forceDarkTheme}
        />
      </header>
    </div>
  );
};

export default Header;
