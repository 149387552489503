"use client";

import { ComponentProps } from "react";

import classNames from "classnames";
import { useTranslations } from "next-intl";

import { Button } from "@components/common/button";
import { useNavigation } from "@hooks/use-navigation";
import { Menu } from "@types";
import { getMenuURL } from "@utils";

type SidebarContentProps = ComponentProps<"div"> & {
  selectedMenu: Menu;
};

const HeaderContent = ({
  className,
  selectedMenu,
}: SidebarContentProps): JSX.Element => {
  const t = useTranslations("common.header");
  const { Link } = useNavigation();

  return (
    <ul
      className={classNames(
        "flex flex-col gap-4 p-6 text-center md:flex-1 md:flex-row md:justify-center md:p-0",
        className,
      )}
    >
      <li>
        <Button
          asChild
          variant={"link"}
          className={classNames("md:text-base", {
            "font-bold": selectedMenu === "home" ? "page" : undefined,
          })}
          aria-current={selectedMenu === "home" ? "page" : undefined}
        >
          <Link href={getMenuURL("home")}>{t("home")}</Link>
        </Button>
      </li>
      <li>
        <Button
          asChild
          variant={"link"}
          className={classNames("md:text-base", {
            "font-bold": selectedMenu === "contact" ? "page" : undefined,
          })}
          aria-current={selectedMenu === "contact" ? "page" : undefined}
        >
          <Link href={getMenuURL("contact")}>{t("contact")}</Link>
        </Button>
      </li>
    </ul>
  );
};

export default HeaderContent;
