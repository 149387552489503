"use client";

import { useState, ComponentProps } from "react";

import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Menu as MenuIcon, X } from "lucide-react";
import { useTranslations } from "next-intl";

import { Button } from "@components/common/button";
import Logo from "@components/logo";
import Tooltip, { TooltipContent, TooltipTrigger } from "@components/tooltip";
import { useNavigation } from "@hooks/use-navigation";
import type { Menu } from "@types";
import { getMenuURL } from "@utils";

import HeaderContent from "../header-content";

const HeaderMobile = ({
  className,
  isMobile,
  selectedMenu,
  forceDarkTheme,
}: ComponentProps<"div"> & {
  isMobile: boolean;
  selectedMenu: Menu;
  forceDarkTheme?: boolean;
}): JSX.Element => {
  const t = useTranslations("common.header");
  const { Link } = useNavigation();
  const [open, setOpen] = useState<boolean>(false);

  const openSidebar = (): void => setOpen((open) => !open);

  return (
    <div className={classNames(className, "flex items-center justify-between")}>
      <Link href={getMenuURL("home")}>
        <Logo
          type="horizontal"
          width={120}
          height={67}
          forceDarkTheme={forceDarkTheme}
        />
      </Link>
      <Tooltip isMobile={isMobile}>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            onClick={openSidebar}
            aria-label={t("openMenu")}
          >
            <MenuIcon className="h-5 w-5" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="left">
          <p>{t("openMenu")}</p>
        </TooltipContent>
      </Tooltip>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{
              duration: 0.3,
              ease: "easeInOut",
            }}
            className={
              "fixed inset-0 z-50 flex h-full w-full flex-col border bg-card shadow"
            }
          >
            <div className="flex items-center justify-center px-6 pt-6">
              <div className="ml-10 flex-1">
                <div className="relative h-24 w-full">
                  <Link href={getMenuURL("home")}>
                    <Logo
                      type="horizontal"
                      fill={true}
                      sizes="100vh"
                      className="object-contain"
                      forceDarkTheme={forceDarkTheme}
                    />
                  </Link>
                </div>
              </div>
              <Tooltip isMobile={isMobile}>
                <TooltipTrigger asChild>
                  <Button variant={"ghost"} size={"icon"} onClick={openSidebar}>
                    <X className="h-5 w-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="left">
                  <p>{t("closeMenu")}</p>
                </TooltipContent>
              </Tooltip>
            </div>
            <HeaderContent selectedMenu={selectedMenu} />
            <div className="p-6 pt-4">
              <Tooltip isMobile={isMobile}>
                <TooltipTrigger asChild>
                  <div>
                    <Button
                      variant={"outline"}
                      disabled
                      className="h-12 w-full"
                    >
                      {t("launchApp")}
                    </Button>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="top">
                  <p>{t("comingSoon")}</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeaderMobile;
