"use client";

import { ArrowLeft } from "lucide-react";
import { useTranslations } from "next-intl";

import { Button } from "@components/common/button";
import { useNavigation } from "@hooks/use-navigation";
import { getMenuURL } from "@utils";

const Status = ({
  type,
  errorCode,
}: {
  type: "404" | "500";
  errorCode?: string;
}): JSX.Element => {
  const t = useTranslations("common.status");
  const { Link } = useNavigation();

  return (
    <section>
      <h1 className="mb-4 text-7xl font-extrabold text-highlight-foreground md:text-9xl">
        {type}
      </h1>
      <p className="mb-4 text-3xl font-bold md:text-4xl">{t(`title${type}`)}</p>
      <p className={"text-lg font-light text-light-foreground"}>
        {t(`description${type}`)}
      </p>
      {type === "500" && errorCode && (
        <p className="mt-1 font-light text-light-foreground">
          {t("errorCode", { errorCode })}
        </p>
      )}
      <Button asChild className="mt-6 max-w-fit" variant={"secondary"}>
        <Link href={getMenuURL("home")}>
          <ArrowLeft className="mr-1 h-5 w-5" />
          {t("back")}
        </Link>
      </Button>
    </section>
  );
};

export default Status;
