"use client";

import { ComponentProps } from "react";

import classNames from "classnames";
import { useTranslations } from "next-intl";

import { Button } from "@components/common/button";
import Logo from "@components/logo";
import Tooltip, { TooltipContent, TooltipTrigger } from "@components/tooltip";
import { useNavigation } from "@hooks/use-navigation";
import type { Menu } from "@types";
import { getMenuURL } from "@utils";

import HeaderContent from "../header-content";


const HeaderDesktop = ({
  className,
  selectedMenu,
  isMobile,
  forceDarkTheme,
}: ComponentProps<"div"> & {
  selectedMenu: Menu;
  isMobile: boolean;
  forceDarkTheme?: boolean;
}): JSX.Element => {
  const t = useTranslations("common.header");
  const { Link } = useNavigation();

  return (
    <div className={classNames(className, "flex h-full items-center")}>
      <Link href={getMenuURL("home")}>
        <Logo
          type="horizontal"
          width={150}
          height={85}
          forceDarkTheme={forceDarkTheme}
        />
      </Link>
      <HeaderContent selectedMenu={selectedMenu} />
      <Tooltip isMobile={isMobile}>
        <TooltipTrigger asChild>
          <div>
            <Button variant={"outline"} disabled>
              {t("launchApp")}
            </Button>
          </div>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          <p>{t("comingSoon")}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export default HeaderDesktop;
