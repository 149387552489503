import { createSharedPathnamesNavigation } from "next-intl/navigation";

import { locales, localePrefix } from "@utils";

export const useNavigation = (): ReturnType<
  typeof createSharedPathnamesNavigation<typeof locales>
> =>
  createSharedPathnamesNavigation({
    locales,
    localePrefix,
  });
